// import { ref } from 'vue'
// import { projectFirestore } from '../firebase/config'

// const menuData = ref(null)

// const menu = async (collection, id) => {
//     const menuRef = projectFirestore.collection(collection);
//     const snapshot = await menuRef.where('userId', '==', id).get();
    
//     if (snapshot.empty) {
//     console.log('No matching documents.')
//     return;
//     }  

//     snapshot.forEach(doc => {
//     // console.log(doc.id, '=>', doc.data())
//     menuData.value = doc.data()
//     console.log(menuData.value)
//     });
// }

// const getMenu = () => {
//     return { menu, menuData }
// }

// export default getMenu


import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'

const getMenu = (collection, query) => {
    const documents = ref(null)
    const error = ref(null)     

    let collectionRef = projectFirestore.collection(collection).orderBy('createdAt')

    if (query) {
        collectionRef = collectionRef.where(...query)
    }

    const unsub = collectionRef.onSnapshot((snap) => {        

        let results = []

        snap.docs.forEach(doc => {
            doc.data().createdAt && results.push({ ...doc.data(), id: doc.id })                                        
        })                                   
                
        if (!snap.empty) {
            documents.value = results
            error.value = null
            console.log('snap exists', !snap.empty)
        } else {
            console.log('is snap empty', snap.empty)
        }

        
    }, (err) => {

        console.log(err.message)
        documents.value = null
        error.value = 'Could not fetch data'

    })

    watchEffect((onInvalidate) => {
        //drop the realtime listener.. remove snapshots.
        onInvalidate(() => unsub()) 
    })

    return { documents, error }
}

export default getMenu