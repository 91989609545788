<template>
<div>
    <div class="update-item item-create">                 
        <button class="close-btn" @click="closeUpdateMenu">Close</button>
        <form @submit.prevent="handleSubmit" id="UpdateItemForm">            
            <div class="error" v-if="error">{{ error }}</div>
            <h4>Update Menu Information</h4>
            <input type="text" required placeholder="Menu Title" v-model="menuTitle">                
            <input type="text" placeholder="Menu Description" v-model="menuDesc">
            <!-- <input type="text" placeholder="Location" v-model="menuLocation"> -->
            <vue-google-autocomplete               
                id="map"                    
                :placeholder="locationPlaceholderText"
                v-on:placechanged="getAddressData"                                                                         
                :enable-geolocation="true"  
                v-model="menuLocation"                
            >
            </vue-google-autocomplete>           
            <input type="tel" pattern="[0-9]{10}" maxlength="12" placeholder="Phone Number - 1234567890" v-model="menuPhoneNumber">
            
            <!-- image upload -->            
            <label>Update Cover Image</label>
            <div class="img-section">
                <input type="file" @change="handleChange">
                <img v-if="menuImgUrl" id="updateImgCover" :src="menuImgUrl" width="130" height="130">                                
                <img v-else id="updateImgCover" width="130" height="130">                                
            </div>                
            <div class="error">{{ fileError }}</div>
            

            <button v-if="!isPending">Update</button>
            <button v-else disabled>Saving...</button>
        </form>
    </div>
</div>  
</template>

<script>
import { ref, computed } from 'vue'
import useStorage from '@/composables/useStorage'
import { projectFirestore } from '@/firebase/config'
import { useRouter } from 'vue-router'
import VueGoogleAutocomplete from 'vue-google-autocomplete'


export default {
    emits: ['closeMenu'],
    props: ['docId', 'document', 'menuTitle', 'menuDesc', 'menuLocation', 'menuLocationUrl', 'menuPhoneNumber', 'menuImgPath', 'menuImgUrl', 'menuId'],   
    components: { VueGoogleAutocomplete }, 
    setup(props, {emit}) {  
        const { filePath, url, uploadImage, deleteImage } = useStorage()

        const uniqid = require('uniqid');  
        
        var title = ref(props.menuTitle)        
        var location = ref(props.menuLocation)
        var locationUrl = ref(props.menuLocationUrl)
         
        const imgUrl = ref('')
        const imgPath = ref('')
        
        const file = ref(null)
        const fileError = ref(null)
        const isPending = ref(false)
        const error = ref(null)
        // const updateMenuItem = ref(null)

        //workaround since v-model doesnt work with google autocomplete        
        const locationPlaceholderText = ref('')            
        if (props.menuLocation === '') {            
            locationPlaceholderText.value = 'Address'
        } else {
            locationPlaceholderText.value = props.menuLocation
        }
        
        const getAddressData = (addressData, placeResultData, id) => {                                    
            location.value = placeResultData.formatted_address
            locationUrl.value = placeResultData.url                       
        }
                
        const closeUpdateMenu = () => {
            emit('closeMenu')
        }

        const handleSubmit = async () => {
            console.log(props.menuTitle, title.value)        
            
            if (props.menuTitle !== title.value) {
                
                const menuRef = projectFirestore.collection('userMenu').orderBy('createdAt')
                const snapshot = await menuRef.where('menuTitle', '==', props.menuTitle.toLowerCase()).get()                
                if (snapshot.empty) {
                    console.log('menu name does not exist') 
                    title.value = props.menuTitle
                    error.value = null
                } else {
                    snapshot.forEach(doc => {
                        console.log('menu exits')
                        if (title.value === props.menuTitle) {
                            console.log('new menu name was created')                        
                        } else {                            
                            error.value = "Menu name already exists please create a new one." 
                            props.menuTitle.value = 'GER'
                            isPending.value = false                                                                     
                            console.log(props.menuTitle, title.value)
                        }                    
                    })
                }
                 
            } 
                
            if (!error.value) {                
                const updateMenu = projectFirestore.collection('userMenu').doc(props.docId)                         
                const imageVal = document.querySelector('input[type="file"]');
                const imageSrc = document.getElementById('updateImgCover');
                
                isPending.value = true        

                if (file.value) {                
                    await deleteImage(props.menuImgPath)            
                    await uploadImage('covers', file.value)
                    imgUrl.value = url.value
                    imgPath.value = filePath.value                
                } else {
                    imgUrl.value = props.menuImgUrl
                    imgPath.value = props.menuImgPath               
                }
                                    
                const newMenu = {
                    menuTitle: title.value.toLowerCase(),              
                    menuUrl: title.value.toLowerCase().replace(/[^A-Z0-9]+/ig, "-"),
                    menuDescription: props.menuDesc,                                
                    menuLocation: location.value,
                    menuLocationUrl: locationUrl.value,
                    menuPhoneNumber: props.menuPhoneNumber,            
                    coverUrl: imgUrl.value,
                    filePath: imgPath.value,             
                }
                                    
                await updateMenu.update(newMenu)

                isPending.value = false
                console.log('menu was updated');                
                emit('closeMenu')
            }
        }

        //allow file type for images
        const types = ['image/png', 'image/jpeg', 'image/png']

        const handleChange = (e) => {        
            const selected = e.target.files[0]         
                    
            if (selected && types.includes(selected.type) ) {
                
                const image = document.getElementById('updateImgCover');            
                image.src = URL.createObjectURL(selected);

                file.value = selected
                fileError.value = null            
                
            } else {
                file.value = null
                fileError.value = 'you can only upload png or jpg file types'
            }
            
        }

        const testInput = () => {
            console.log(props.menuTitle)
        }

        return { locationPlaceholderText, testInput, location, locationUrl, getAddressData, closeUpdateMenu, isPending, fileError, title, imgPath, imgUrl, file, handleSubmit, handleChange, error }
    }
}
</script>

<style scoped>

img {
    width:100%;
    border-radius:10px;
    object-fit: cover;
    background:#c0c0c0;
}

.img-section input {
    border:none;
}

.item-create {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    position: absolute;
    max-width: 500px;    
    width:100%;
    max-height:100%;
    overflow:scroll;
}

form button {
    width:100%;
}
</style>