<template>
    <div>
        <p>{{ user.displayName }}</p>
        <p>{{ user.email }}</p>
    </div>
</template>

<script>
import getUser from '@/composables/getUser'

export default {
    setup() {
        const { user } = getUser() 

        console.log(user)

        return {user}
    }
}    
</script>