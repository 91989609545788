import { projectStorage } from '@/firebase/config'
import { ref } from 'vue'
import getUser from '@/composables/getUser'

const { user } = getUser();

const useStorage = () => {
    const error = ref(null)
    const url = ref(null)
    const filePath = ref(null)

    const uploadImage = async (dir, file) => {
        const uniqid = require('uniqid');
        const uniqFile = uniqid()        

        filePath.value = `${dir}/${user.value.uid}/${uniqFile+'-'+file.name}`
        const storageRef = projectStorage.ref(filePath.value)                

        try {
            const res = await storageRef.put(file)
            url.value = await res.ref.getDownloadURL()

            error.value = null;

        } catch(err) {
            console.log(err.message);
            error.value = err.message
        }
    }

    const deleteImage = async (path) => {
        const storageRef = projectStorage.ref(path)

        try {
            await storageRef.delete()

        } catch (err) {
            console.log(err.message)
            error.value = err.message
        }
    }

    return { url, filePath, error, uploadImage, deleteImage }
}

export default useStorage