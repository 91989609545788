<template>
  <div class="navbar">
    <nav>      
      <h1><router-link :to="{ name: 'Home' }">QROMB</router-link></h1>
      
      <div class="links">
        <div v-if="user">
          <!-- <router-link :to="{ name: 'CreatePlaylist' }">Create Playlist</router-link>
          <router-link :to="{ name: 'UserPlaylists' }">My Playlists</router-link> -->
          <!-- <router-link :to="{ name: 'UserMenu', params: {'menuTitle' : menuTitle} }">My Menu</router-link>           -->
          <!-- <router-link :to="{ name: 'MenuBuilder'}">My Menu</router-link>           -->
          <router-link :to="{ name: 'Dashboard' }">Dashboard</router-link>
          <!-- <span>Menu Title is {{ menuTitle }}</span> -->
          <button @click="handleClick">Logout</button>
        </div>
        <div v-else>
          <router-link class="btn" :to="{ name: 'Signup' }">Signup</router-link>
          <router-link class="btn" :to="{ name: 'Login' }">Login</router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
// challenge
//   - only show the logout button if we are logged in
//   - only show the signup and login links if we are not logged in
//   - use the getUser composable to help
import getUser from '@/composables/getUser'
import useLogout from '@/composables/useLogout'
import { useRouter } from 'vue-router'

import { onBeforeMount, ref } from 'vue'
import getMenu from '@/composables/getMenu'


export default {
  setup() {
    const { user } = getUser()
    const { logout } = useLogout()
    const router = useRouter()

    // const { menu } = getMenu()
    // const menuTitle = ref(null)

    // onBeforeMount( async () => {
    //   const result = await menu(user.value.uid)
    //   console.log('from NavBar -> result title = ', result.menuTitle.value)
    //   menuTitle.value = result.menuTitle.value
    //   return result
    // })

    const handleClick = async () => { 
      router.push({ name: 'Home' })           
      await logout()        
    }

    return { handleClick, user }
  }
}
</script>

<style scoped>
  .navbar {
    position:sticky;
    position: -webkit-sticky;
    top:0;
    padding: 16px 20px;    
    background: white;
    z-index: 9;
    border-bottom:1px solid var(--secondary);
  }
  nav {
    display: flex;
    align-items: center;
    max-width: 1700px;
    margin: 0 auto;
  }
  nav img {
    max-height: 60px;
  }  
  nav .links {
    margin-left: auto;
  }
  nav .links a, button {
    margin-left: 16px;
    font-size: 14px;
  }
  span {
    font-size:14px;
    display:inline-block;
    margin-left:16px;
    padding-left:16px;
    border-left:1px solid #eee;
  }

@media (max-width:600px) {
    .navbar {    
    padding: 8px 10px;    
  }
}
</style>