<!-- 
User Menu,
Show user items,
Show user categories
All editing options for user dashboard?
This is our dashboard..
-->

<template>    
    <div>              
        <div v-for="doc in documents" :key="doc.id" class="menu-layout">            
            <ShowMenu                
                :title="doc.menuTitle"
                :description="doc.menuDescription"
                :location="doc.menuLocation"
                :locationUrl="doc.menuLocationUrl"
                :phone="doc.menuPhoneNumber"
                :url="doc.menuUrl"
                :imgUrl="doc.coverUrl"
                :imgPath="doc.filePath"   
                :id="doc.userId"             
            />            
        </div>   
    </div>
</template>

<script>
//Composables
import getCollection from '@/composables/getCollection'
import getUser from '@/composables/getUser'
import getMenu from '@/composables/getMenu'
//Components
import ShowMenu from '@/components/ShowMenu.vue'
//Vue
import { ref, watchEffect, computed  } from 'vue'
import { useRouter, useRoute } from 'vue-router'
//Firebase
import { projectFirestore } from '@/firebase/config'

export default {
    // props: ['menuTitle'],    
    components: { ShowMenu },
    setup() {        
        const route = useRoute()        
        const {user} = getUser()        
        const { documents } = getMenu('userMenu', [ 'menuUrl', '==', route.params.menuUrl])        

        return { documents }
    }
}
</script>

<style>
    .menu-layout {
        margin: 0 auto;
        max-width: 1700px;
    }
</style>