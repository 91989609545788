import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'

const getCollection = (collection, order, query) => {
    const documents = ref(null)
    const error = ref(null)
    const exists = ref(0)    

    let collectionRef = projectFirestore.collection(collection).orderBy(order)

    if (query) {
        collectionRef = collectionRef.where(...query)
    }

    const unsub = collectionRef.onSnapshot((snap) => {        

        let results = []

        snap.docs.forEach(doc => {
            doc.data().createdAt && results.push({ ...doc.data(), id: doc.id })                            
        })     
        
        exists.value = results.length                
        documents.value = results
        error.value = null
        
    }, (err) => {

        console.log(err.message)
        exists.value = 0
        documents.value = null
        error.value = 'Could not fetch data'
        
    })

    watchEffect((onInvalidate) => {
        //drop the realtime listener.. remove snapshots.
        onInvalidate(() => unsub()) 
    })

    return { documents, error, exists }
}

export default getCollection