<template>   
    <div v-if="isLoaded" >        
           
        <div v-if="exists" class="my-menu-list">
            <div v-for="menu in menuVal" :key="menu.id">
                           
                <router-link class="menu-selection" :to="{ name: 'MenuBuilder'}">
                    <div class="menu-thumbnail">
                        <img :src="menu.coverUrl" >
                        <div class="menu-thumbnail-info">
                            <h3>{{menu.menuTitle}}</h3>
                            <span>
                                <p>{{catAmount}} Categories</p>
                                <p>{{amountItems}} Items</p>
                            </span>
                        </div>                                                                             
                    </div>   

    
                </router-link>   

            </div>                                
        </div>
        <button v-if="!exists" class="new-menu-btn" @click="toggleCreateMenu">CREATE NEW MENU</button>
            
        <div v-if="showCreateMenu" class="floater">
            <CreateMenu @closeMenu="toggleCreateMenu" />
        </div>
    </div>  
</template>

<script>
import getUser from '@/composables/getUser'
import { ref, watchEffect } from 'vue'
import CreateMenu from '@/components/CreateMenu.vue'
import { projectFirestore } from '@/firebase/config'
import { useRouter } from 'vue-router'
import getCollection from '@/composables/getCollection'


export default {
    components:{CreateMenu},
    setup() {         

        const { user } = getUser()  
        const isLoaded = ref(false)
        const exists = ref(false)
        const menuVal = ref([])

        let menuRef = projectFirestore.collection('userMenu').orderBy('createdAt').where('userId', '==', user.value.uid)
        const unsub = menuRef.onSnapshot((snap) => { 
            snap.docs.forEach(doc => {                    
                menuVal.value = [doc.data()]                
            }) 

            if (!snap.empty) {                
                exists.value = true
            } else {                
                exists.value = false                
            }

            isLoaded.value = true
        })

        watchEffect((onInvalidate) => {        
            onInvalidate(() => unsub()) 
        })

        const amountItems = ref(0)
        const catAmount = ref(0)
        const getItemCount = () => {            
            let categoryRef = projectFirestore.collection('menuCategory').orderBy('createdAt').where('userId', '==', user.value.uid)
            const unSnap = categoryRef.onSnapshot((snap) => { 
                snap.docs.forEach(doc => {                   
                    catAmount.value += 1                                  
                    amountItems.value += doc.data().menuItems.length              
                }) 

                if (snap.empty) {                
                    catAmount.value = 0
                    amountItems.value = 0
                }             
            })

            watchEffect((onInvalidate) => {            
                onInvalidate(() => unSnap()) 
            })
                        
        }
        getItemCount()
        
                        
        const showCreateMenu = ref(false)  
        const toggleCreateMenu = () => {
            showCreateMenu.value = !showCreateMenu.value
        }
        
        return { catAmount, amountItems, exists, isLoaded, menuVal, toggleCreateMenu, showCreateMenu}
    },


}

</script>

<style scoped>
    .menu-selection {
        margin-top:20px;
        display:inline-block;
    }

    .menu-thumbnail img {
        width:100%;
        height:100px;
        object-fit:cover;
        display:block;
        background:#4f515a;
        border-radius:8px;  
        border-bottom-left-radius: 0px;      
        border-bottom-right-radius: 0px;
    }

    .menu-thumbnail {
        width: 100%;
        max-width: 300px;        
        border:1px solid var(--corvette);
        box-sizing: border-box;
        font-size: 20px;
        border-radius:8px;        
    }

    .menu-thumbnail-info {
        padding:5px 10px;
        text-transform: capitalize;
    }

    .menu-thumbnail-info span {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
    };


    .new-menu-btn {
        width: 100%;
        max-width: 300px;
        height: 170px;
        border: 7px dashed var(--corvette);
        background: none;
        box-sizing: border-box;
        font-size: 20px;
        font-weight: bolder;
        color:#ebebeb;        
    }

    .new-menu-btn:hover {
        background:grey;
        color:white;
        border:solid;
    }

    .floater { display:block }
</style>