<template>
    <div class="abs-center floater-size">
      <button class="close-btn" @click="closeModalCategory">Close</button>
      <form @submit.prevent="createCategory">
        <h4>Create a New Category</h4>
        <input type="text" required placeholder="Category Name" v-model="title">
        <button v-if="!isPending">Create</button>
        <button v-else disabled>Saving...</button>
        <div class="error">{{ error }}</div>
      </form>
    </div>
</template>

<script>
import { ref } from 'vue'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { useRouter } from 'vue-router'

export default {
  emits: ['closeCategory'],
  setup(props, { emit }) {    
        const router = useRouter()
        const { user } = getUser() 
        const { addDoc, error, isPending } = useCollection('menuCategory')                     

        const title = ref('')
        
        const createCategory = async () => {

            const getPosition = document.querySelectorAll('.single-category').length
            isPending.value = true
            
            const category = {
                title: title.value, 
                titleAnchor: title.value.toLowerCase().replace(/[^A-Z0-9]+/ig, "-"), 
                userId: user.value.uid,
                userName: user.value.displayName,    
                position: getPosition,                               
                menuItems: [],
                createdAt: timestamp()
            }

            await addDoc(category)
            if (!error.value) {                
                isPending.value = false
                title.value = '';
                emit('closeCategory')
            } else {
                error.value = "There was an issue creating your category"
                isPending.value = false
            }  
            
        }
            
        const closeModalCategory = () => {
          emit('closeCategory')
        }        

        return {  closeModalCategory, title, createCategory, error, isPending }
    }    
}

</script>

<style scoped>
  form {
    background: white;
  }
  input[type="file"] {
    border: 0;
    padding: 0;
  }
  label {
    font-size: 12px;
    display: block;
    margin-top: 30px;
  }
</style>