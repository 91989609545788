<template>
    <div class="menu-list">
        <div class="menu-item" v-for="menu in menus" :key="menu.id">            
            <router-link :to="{ name: 'UserMenu', params: {'menuUrl' : menu.menuUrl} }">
                <div class="menu-thumbnail">
                    <img :src="menu.coverUrl" >
                    <div class="menu-thumbnail-info">
                        <h3>{{menu.menuTitle}}</h3>                        
                    </div>                                                                             
                </div>                  
            </router-link>   
        </div>
    </div>
</template>

<script>
import { projectFirestore } from '@/firebase/config'
import getCollection from '@/composables/getCollection'
import { useRouter } from 'vue-router'

export default {
    setup() {        
        const { documents:menus, error } = getCollection('userMenu', 'createdAt')

        

        return { menus, error }
    }
}    
</script>

<style scoped>
    .menu-list {
        display:grid;
        grid-template-columns:repeat(3,1fr);
        grid-gap:20px;
    }
    @media (max-width:600px) {
        .menu-list {         
            grid-template-columns:1fr;
        }
    }  
    
    .menu-item {
        display: inline-block;
        margin: 0 auto;
    }

    .menu-thumbnail img {
        width:100%;
        height:100px;
        object-fit:cover;
        display:block;
        background:#4f515a;
        border-radius:8px;  
        border-bottom-left-radius: 0px;      
        border-bottom-right-radius: 0px;
    }

    .menu-thumbnail {
        width: 100%;
        max-width: 300px;        
        border:1px solid var(--corvette);
        box-sizing: border-box;
        font-size: 20px;
        border-radius:8px;        
    }

    .menu-thumbnail-info {
        padding:5px 10px;
        text-transform: capitalize;
    }
</style>
