<template>
     <form @submit.prevent="createCategory">
      <h4>Create a New Category</h4>
      <input type="text" required placeholder="Category Name" v-model="title">
      <button v-if="!isPending">Create</button>
      <button v-else disabled>Saving...</button>
       <div class="error">{{ error }}</div>
    </form>
</template>

<script>
import { ref } from 'vue'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { useRouter } from 'vue-router'

export default {
  setup() {    
        const router = useRouter()
        const { user } = getUser() 
        const { addDoc, error, isPending } = useCollection('menuCategory')                     

        const title = ref('')
        const getPosition = document.querySelectorAll('.single-category').length
        console.log(getPosition)

        const createCategory = async () => {
            // isPending.value = true
            
            // const category = {
            //     title: title.value, 
            //     userId: user.value.uid,
            //     userName: user.value.displayName,    
            //     position: 1,                               
            //     menuItems: [],
            //     createdAt: timestamp()
            // }

            // await addDoc(category)
            // if (!error.value) {                
            //     isPending.value = false
            //     router.push({ name: "Dashboard" })
            // } else {
            //     error.value = "There was an issue creating your category"
            //     isPending.value = false
            // }   
            
            
        }

        return { title, createCategory, error, isPending }
    }    
}

</script>

<style scoped>
  form {
    background: white;
  }
  input[type="file"] {
    border: 0;
    padding: 0;
  }
  label {
    font-size: 12px;
    display: block;
    margin-top: 30px;
  }
  button {
    margin-top: 20px;
  }
</style>