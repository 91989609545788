
const stickyScroll = () => {

    const headerHeight = document.querySelector('.navbar').offsetHeight

    const stickyElm = document.querySelector('.top-cat-list')        
    const stickyElmPos = stickyElm.getBoundingClientRect().top - headerHeight                
    const stickyHeaderTopHeight = (headerHeight + stickyElm.offsetHeight) + 40 
    const stickyHeaderBottomHeight = (headerHeight + stickyElm.offsetHeight) + 100 

    const scrollCat = document.querySelectorAll('.category')
    const scrollCatList = document.querySelectorAll('.top-cat-list-holder span')
    const scrollCatTop = []
    const scrollCatBottom = []

    for (var i = 0; i < scrollCat.length; i++ ) {                
            scrollCatTop.push( scrollCat[i].getBoundingClientRect().top - stickyHeaderTopHeight )
            scrollCatBottom.push( scrollCat[i].getBoundingClientRect().bottom - 63 )                           
    }
                            
    window.addEventListener('scroll', function() { 
        
        window.scrollY >= stickyElmPos ? stickyElm.classList.add('isSticky') : stickyElm.classList.remove('isSticky')

        for (var i = 0; i < scrollCat.length; i++ ) {
            if (window.scrollY >= scrollCatTop[i] && window.scrollY <= scrollCatBottom[i]  ) {
                scrollCatList[i].classList.add('active')
            } else {
                scrollCatList[i].classList.remove('active')
            }                
        }            

    })

}


export default stickyScroll