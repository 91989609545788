<template>
  <div class="content">
    <Navbar />
    <router-view/>
    
  </div>
</template>

<script>
  import Navbar from '@/components/Navbar'

  export default {
    components: { Navbar }
    
  }
</script>

<style>
  .content {
    margin:0 auto;
    max-width:2222px;    
  }
</style>
