<template>
<div>
  <div class="error" v-if="error">{{ error }}</div>
  <div v-if="meunCat" class="meunCat-details">

    <!-- category information -->
    <div class="category-info">      
      <h2>{{ meunCat.title }}</h2>      
      <button v-if="ownership" @click="handleDelete"> Delete Category </button>
    </div>

    <!-- Item list -->
    <!-- <div v-if="!meunCat.menuItems.length">This Category currently has no items.</div> -->
    <div class="menu-items-list">
        <div v-for="item in meunCat.menuItems" :key="item.id" class="item-single">
            <div class="details">
                <h3>{{item.title}}</h3>  
                <img :src="item.coverUrl">
                <p>{{item.description}}</p>
                <p>${{item.price}}</p>
            </div>
            <button v-if="ownership" @click="deleteSong(item.id)">Delete</button>
        </div>  
    </div>

    <AddItem v-if="ownership"  :meunCat="meunCat" />
    
    
  </div>
</div>  
</template>

<script>
import AddItem from '@/components/AddItem.vue'
import getDocument from '@/composables/getDocument'
import getUser from '@/composables/getUser'
import { computed } from 'vue'
import useDocument from '@/composables/useDocument'
import useStorage from '@/composables/useStorage'
import { useRouter } from 'vue-router'

export default {
  props: ['id'],
  components: { AddItem },
  setup(props) {
    const { error, document: meunCat } = getDocument('menuCategory', props.id)
    const { user } = getUser()
    const { deleteDoc, updateDoc } = useDocument('menuCategory', props.id)
    const { deleteImage } = useStorage()
    const router = useRouter()

    const ownership = computed(() => {
      return meunCat.value 
        && user.value 
        && user.value.uid == meunCat.value.userId
    })

    const handleDelete = async () => {
        // await deleteImage(meunCat.value.filePath)
        // await deleteDoc()    
        deleteDoc()
        router.push({ name: 'UserMenu' })  
        console.log(error)  
    }

    const deleteSong = async (id) => {
        const menuItems = meunCat.value.menuItems.filter((item) => item.id != id )
        updateDoc({ menuItems })
    }

    return { error, meunCat, ownership, handleDelete, deleteSong }
  }
}
</script>

<style scoped>
    .menu-items-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .menu-items-list img {
        width: 100%;
        height:130px;
        object-fit: cover; 
    }

    .category-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .category-info button {
        margin-top:0px;
    }
</style>