<template>
    <div class="abs-center floater-size">
      <button class="close-btn" @click="closeUpdateCategory">Close</button>
      <form @submit.prevent="updateCategory">
        <h4>Update Category</h4>
        <input type="text" required placeholder="Category Name" v-model="menuCatValue">
        <button v-if="!isPending">Update</button>
        <button v-else disabled>Saving...</button>        
      </form>
    </div>
</template>

<script>
import { ref } from 'vue'
import { projectFirestore } from '@/firebase/config'
import { useRouter } from 'vue-router'
import useDocument from '@/composables/useDocument'


export default {
  emits: ['closeCategoryUpdate'],  
  props: ['menuCatId', 'menuCatValue'],   
  setup(props, {emit}) {  
    const title = ref('')    
    const isPending = ref(false)
    
    const closeUpdateCategory = () => {
        emit('closeCategoryUpdate')        
    }

    const updateCategory = async (e) => {

        title.value = props.menuCatValue
        isPending.value = true  
        
        const updateMenuCategoru = projectFirestore.collection('menuCategory').doc(props.menuCatId) 

        await updateMenuCategoru.update({                            
            title: title.value,
            titleAnchor: title.value.toLowerCase().replace(/[^A-Z0-9]+/ig, "-"), 
        })

        isPending.value = false        
        emit('closeCategoryUpdate') 

    }



    return { closeUpdateCategory, updateCategory, title, isPending, }
  }
}
</script>

<style scoped>
    img {
        border-radius:10px;
        object-fit: cover;
        background:#c0c0c0;
    }

    .img-section {
        display:grid;
        grid-template-columns:1fr 130px;
    }

    .img-section input {
        border:none;
    }

    .item-create {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        position: absolute;
        max-width: 500px;    
        width:100%;
        max-height:100%;
        overflow:scroll;
    }
</style>