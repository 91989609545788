import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const  firebaseConfig = {
    apiKey: "AIzaSyC0mw4AEpHnWJ_3jLD2JwCn9ohyPR765K8",
    authDomain: "qromb-bbc4d.firebaseapp.com",    
    projectId: "qromb-bbc4d",
    storageBucket: "qromb-bbc4d.appspot.com",
    messagingSenderId: "465726043660",
    appId: "1:465726043660:web:041d2652db24835cb6a029"
  };
//init firebase
firebase.initializeApp(firebaseConfig)

//init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()
const googleAuth = new firebase.auth.GoogleAuthProvider();

//timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectFirestore, projectAuth, projectStorage, timestamp, googleAuth }
  
