<template>
<div>
    <div class="item-show">                 
        <button class="close-btn" @click="closeItemModal">Close</button>
        <img v-if="imgUrl" :src="imgUrl"/>
        <h3 v-if="title">{{title}}</h3>
        <p v-if="desc">{{desc}}</p>
        <p v-if="price">{{price}}</p>        
    </div>
</div>  
</template>

<script>
import { ref } from 'vue'
// :imgUrl="item.coverUrl" :title="item.title" :desc="item.description" :price="item.price" 
export default {
  emits: ['closeItem'],
  props: ['title', 'desc', 'price', 'imgUrl'],  
  setup( props, {emit} ) {         

    const closeItemModal = () => {
        emit('closeItem')        
    } 

    return {closeItemModal}
  }
}
</script>

<style scoped>  
    .item-show {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        position: absolute;
        max-width: 500px;    
        width:100%;
        max-height:100%;
        overflow:scroll;
        background:white;
        padding:20px;
        border-radius:8px;
        box-sizing:border-box;
    }

    .item-show img {
        width:100%;
    }
</style>