<template>
<div>
    <div class="update-item item-create">                 
        <button class="close-btn" @click="closeUpdateItem">Close</button>
        <form @submit.prevent="handleSubmit" id="UpdateItemForm">            
            <h4>Update Item</h4>
            <input type="text" required placeholder="Update title" v-model="itemTitle">
            <textarea placeholder="Update description..." v-model="itemDesc"></textarea>
            <input type="text" placeholder="Update price" v-model="itemPrice">

            <!-- lets edit varients -->
            
            <!-- image upload -->            
            <label>Change Item Image</label>
            <div class="img-section">
                <input type="file" @change="handleChange">
                <img v-if="itemImgUrl" id="updateImgPreview" :src="itemImgUrl" width="130" height="130">                                
                <img v-else id="updateImgPreview" width="130" height="130">                                
            </div>                
            <div class="error">{{ fileError }}</div>
            

            <button v-if="!isPending">Update</button>
            <button v-else disabled>Saving...</button>
        </form>
    </div>
</div>  
</template>

<script>
import { ref } from 'vue'
import useStorage from '@/composables/useStorage'
import { projectFirestore } from '@/firebase/config'
import { useRouter } from 'vue-router'


export default {
  emits: ['closeItemUpdate'],  
  props: ['menuCat', 'menuCatId', 'itemTitle', 'itemDesc', 'itemPrice', 'itemImgUrl', 'itemPath', 'itemId'],   
  setup(props, {emit}) {  
    const { filePath, url, uploadImage, deleteImage } = useStorage()
    
    const title = ref('')
    const description = ref('')
    const price = ref('')
    const file = ref(null)
    const fileError = ref(null)
    const isPending = ref(false)
    const updateMenuItem = ref(null)    
    
    const closeUpdateItem = () => {        
        emit('closeItemUpdate')
    }

    const handleSubmit = async () => {

        const getCategory = props.menuCat.filter(cat => cat.id === props.menuCatId);                        
        const getItem =  getCategory[0].menuItems.filter(item => item.id === props.itemId); 
        const updateMenuItems = projectFirestore.collection('menuCategory').doc(props.menuCatId) 
                
        const imageVal = document.querySelector('input[type="file"]');
        const imageSrc = document.getElementById('updateImgPreview');
        
        isPending.value = true

        if (file.value) {
            console.log('deleteing', props.itemPath)
            await deleteImage(props.itemPath)            
            await uploadImage('menuItems', file.value)
            getItem[0].coverUrl = url.value
            getItem[0].filePath = filePath.value
        }  else {
            getItem[0].coverUrl = props.itemImgUrl
            getItem[0].filePath = props.itemPath
        }

        getItem[0].title = props.itemTitle
        getItem[0].description = props.itemDesc
        getItem[0].price = props.itemPrice
        getItem[0].id = props.itemId

        updateMenuItem.value = getCategory[0].menuItems
                    
        await updateMenuItems.update({                            
                menuItems: updateMenuItem.value           
        })

        isPending.value = false
        console.log('item was updated');                
        emit('closeItemUpdate')

    }

    //allow file type for images
    const types = ['image/png', 'image/jpeg', 'image/png']

    const handleChange = (e) => {        
        const selected = e.target.files[0] 
                
        if (selected && types.includes(selected.type) ) {
            
            const image = document.getElementById('updateImgPreview');            
            image.src = URL.createObjectURL(selected);

            file.value = selected
            fileError.value = null

        } else {
            file.value = null
            fileError.value = 'you can only upload png or jpg file types'
        }        
    }


    return { closeUpdateItem, isPending, fileError, title, description, price, file, handleSubmit, handleChange }
  }
}
</script>

<style scoped>
    img {
        border-radius:10px;
        object-fit: cover;
        background:#c0c0c0;
    }

    .img-section {
        display:grid;
        grid-template-columns:1fr 130px;
    }

    .img-section input {
        border:none;
    }

    .item-create {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        position: absolute;
        max-width: 500px;    
        width:100%;
        max-height:100%;
        overflow:scroll;
    }

    form button {
        width:100%;
    }
</style>