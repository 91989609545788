<!-- 
User Menu,
Show user items,
Show user categories
All editing options for user dashboard?
This is our dashboard..
-->

<template>    
    <div v-if="isLoaded">              
        <!-- <div id="editing-bar">
            <button @click="toggleEdit">
                <p v-if="editing">VIEW MODE</p>
                <p v-else>EDIT MODE</p>
            </button>
            
        </div> -->

        <div v-if="exists">
            <MenuCover :document="documents" :docId="docId" :edit="editing"/>   
            
  
            <div class="dashboard-layout">                                                      
                    <div v-for="doc in documents" :key="doc.id">                                                
                        <div v-if="menuCats">
                            <MenuCategoryList :menuCats="menuCats" :edit="editing"/>
                        </div>
                    </div>                            
            </div> 

        </div>
         <div v-else >
            <h1>You do not yet have a menu, Create your first menu</h1>
            <router-link class="btn" :to="{ name: 'CreateMenu' }">Create Menu</router-link>                    
        </div>  

    </div>    
</template>

<script>
import getUser from '@/composables/getUser'
import { projectFirestore } from '@/firebase/config'
import { ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import MenuCategoryList from '@/components/MenuCategoryList.vue'
import MenuCover from '@/components/MenuCover.vue'
import getCollection from '@/composables/getCollection'


export default {
    components: { MenuCategoryList, MenuCover },
    setup() {   
        const editing = ref(true)  
        const toggleEdit = () => {
            editing.value = !editing.value            
        }      

        const { user } = getUser()  
        const isLoaded = ref(false)
        const exists = ref(false)
        const documents = ref([])     
        const docId = ref(null)   
        const router = useRouter()

        let menuRef = projectFirestore.collection('userMenu').orderBy('createdAt').where('userId', '==', user.value.uid)
        const unsub = menuRef.onSnapshot((snap) => { 
            snap.docs.forEach(doc => {                    
                documents.value = [doc.data()]   
                docId.value = doc.id            
            }) 

            if (!snap.empty) {                
                exists.value = true
            } else {                
                exists.value = false   
                //we currently push back.. but disable or remove the buttin in nav bar if it doesnt exists.
                router.push({'name':'Dashboard'})
            }

            isLoaded.value = true
        })

        watchEffect((onInvalidate) => {
        //drop the realtime listener.. remove snapshots.
        onInvalidate(() => unsub()) 
        })
                        
        const { documents:menuCats } = getCollection('menuCategory', 'position', [ 'userId', '==', user.value.uid ])        
        
        return { docId, exists, isLoaded, user, documents, menuCats, toggleEdit, editing}
    },
}

</script>

<style scoped>
    #editing-bar {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 50%;
        transform: translate(-50%, 15px);
        display: flex;
        align-items: center;
    }

    .dashboard-layout {
        margin: 0 auto;        
    }

    .section {
        background:white;
        border-radius:20px;
        padding:20px;
    }

    .menu h1 {
        text-align:center;
    }

    .profile {
        height: 300px;
        position: sticky;
        top: 10px;
    }


</style>