<!-- 
This is our category list.
1. amount of items
2. edit category page? 
-->

<template>
<div> 
    <div class="menu-cover">
        <div class="cover-img">  
            <img :src="imgUrl"/>                            
            <h1>{{title}}</h1>                
        </div>   
        <div class="menu-info">        
            <div class="info-section popup">
                <p>{{description}}</p>            
                <a :href="locationUrl" target="_blank"><p>{{location}}</p></a>
                <p>{{phone}}</p>    
                <!-- 
                share options         
                save items to list
                comment on items                                
                -->
            </div> 
        </div>
    </div>   
    
    <div class="top-cat-list">    
        <div class="top-cat-list-holder">             
            <span v-for="cat in menuCats" :key="cat.id">
                <p @click="scrollFix(cat.titleAnchor)">{{cat.title}}</p>                                        
            </span>        
        </div>        
    </div>
    
    <div class="menus">
        <div v-for="cat in menuCats" :key="cat.id">        
            <div class="single-category popup">                       
                
                <div class="category" :data-cat-id="cat.id" :data-pos="cat.position">                
                    <h3 :id="cat.titleAnchor" class="section-title">{{ cat.title }}</h3>  

                    <div class="menu-items-list">                
                        <div v-for="item in cat.menuItems" :key="item.id" class="item" :data-item-id="item.id" @click="viewItem">
                            <div class="item-details">
                                <section class="text">
                                    <h3>{{item.title}}</h3>                              
                                    <p class="desc">{{item.description}}</p>
                                    <p class="price">${{item.price}}</p>
                                </section>
                                <section v-if="item.coverUrl" class="image">
                                    <img :src="item.coverUrl">
                                </section>
                            </div>                  
                        </div>   
                    </div>

                </div> 

            </div>       
        </div>
    </div> 

    <div v-if="showItem" class="floater" >                       
        <ShowItem  @closeItem="closeViewItem" :imgUrl="getImgUrl" :title="getTitle" :desc="getDesc" :price="getPrice" />         
    </div>           

</div>  
</template>

<script>
import getCollection from '@/composables/getCollection'
import VueQRCodeComponent from 'vue-qrcode-component'
import { ref, onMounted, onUpdated } from 'vue'
import ShowItem from '@/components/ShowItem'
import stickyScroll from '@/composables/stickyScroll'

   
export default {
  props: ['title', 'id', 'description', 'location', 'locationUrl', 'phone', 'url', 'imgUrl', 'imgPath'],
  components: {qrcode: VueQRCodeComponent, ShowItem},
  setup(props) {
    const { documents:menuCats } = getCollection('menuCategory', 'position', [ 'userId', '==', props.id ])    
    const qrUrl = ref(location.origin + '/menu/' + props.url)    
    
    const showItem = ref(false)
    const getTitle = ref('')
    const getDesc = ref('')
    const getPrice = ref('')
    const getImgUrl = ref('')

    const viewItem = (e) => {            
        const itemId = e.target.getAttribute('data-item-id')

        const currentItem = document.querySelector(`.item[data-item-id="${itemId}"]`)
        const itemTitle = document.querySelector(`.item[data-item-id="${itemId}"] .text h3`).innerText
        const itemDesc = document.querySelector(`.item[data-item-id="${itemId}"] .text .desc`).innerText
        const itemPrice = document.querySelector(`.item[data-item-id="${itemId}"] .text .price`).innerText
        const itemImgUrl = document.querySelector(`.item[data-item-id="${itemId}"] img`)
               
        itemTitle ? getTitle.value = itemTitle : getTitle.value = false
        itemDesc ? getDesc.value = itemDesc : getDesc.value = false
        itemPrice ? getPrice.value = itemPrice : getPrice.value = false
        itemImgUrl ? getImgUrl.value = itemImgUrl.getAttribute('src') : getImgUrl.value = false

        showItem.value = !showItem.value
    }
    const closeViewItem = () => {        
       showItem.value = !showItem.value 
    }

    const scrollFix = (hashbang) => {            
        location.href = '#' + hashbang;

        const id = hashbang;
        const yOffset = -140; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;    

        window.scrollTo({top: y});  
    }
 
    
    onUpdated(() => {
        stickyScroll()
    })

   
    return { closeViewItem, showItem, viewItem, scrollFix, menuCats, qrUrl, getTitle, getDesc, getPrice, getImgUrl }
  }
}
</script>

<style scoped>

.top-cat-list {
    position: sticky;
    position: -webkit-sticky;
    top: 68px;
    z-index: 9;
    background: var(--bg);
    padding: 10px 20px;
    margin:30px 0px;    
}

@media(max-width:600px) {
    .top-cat-list {
        grid-template-columns: 1fr;
        padding:10px 10px;
        top:52px;
    }
}

.top-cat-list span {
    margin-right:20px;
    text-transform: uppercase;
    font-weight: bold;
}

.top-cat-list span p {
    cursor:pointer;
}

.top-cat-list .active p {
    text-decoration:underline;
}

.isSticky {
    border-bottom: 1px solid var(--secondary);
}

.top-cat-list-holder {
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
}

.menus {
    padding: 0px 20px 20px;
}

.cat-info-bar {
    display: grid;
    grid-template-columns: 170px 1fr;    
    align-items: center;
    margin-bottom: 20px;
    /* background: #fafafa; */
    padding: 10px;
    border-radius: 10px;
}

.cat-info-bar span{ 
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.cat-info-bar h3 {
    text-transform: capitalize;
    font-weight: bold;
}


.cat-option-btns {
    display: inline-flex;
    justify-content: flex-end; 
}

.cat-option-btns button {
    margin-left:10px;
}

.single-category {      
    margin-bottom:40px;
}

.item-details {
    display:grid;
    grid-template-columns:1fr auto;
    grid-gap:10px;
    padding:10px;
    border:1px solid var(--secondary);
    /* border-bottom:5px solid var(--corvette); */
    border-radius:10px;
    background:white;
    pointer-events: none;
}

.item-details section {
    height:110px;
    position:relative;
}

.item-details .text h3 {
    text-transform: capitalize;
}

.item-details .text .desc{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.item-details .text .price{
    position:absolute;
    bottom:0;
    font-weight:bold;    
}

.item-details img {
    width: 110px;
    height:110px;
    object-fit: cover; 
    border-radius:10px;   
}

.delete {
    margin:0px;
    text-align: center;
    padding: 0;
}

.menu-items-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:20px;
}

@media(max-width:1300px) {
    .menu-items-list {     
        grid-template-columns: repeat(2, 1fr);        
    }  
}

@media(max-width:765px) {
    .menu-items-list {     
        grid-template-columns: repeat(1, 1fr);        
    }  
}

.section-title {
    text-transform: uppercase;
    margin-bottom:10px;    
}

.cover-img {
    width:100%;
    height:211px;
    display: block;
    position:relative;
    background:#eee;
}

.cover-img:after {
    content:'';
    width:100%;
    height:211px;
    position:absolute;
    top:0;
    left:0;
    background:black;
    opacity:0.33;
    z-index: 1;        
}    

.cover-img img {
    width:100%;
    height:211px;
    object-fit: cover;
}

h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: capitalize;
    color:white;
    z-index: 2;
}


.menu-info {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;    
    padding: 20px 20px;
    padding-bottom:0px;
}

.info-section p {
    margin-bottom:10px;
}

@media(max-width:600px) {
   .menus {
        padding: 0px 10px 20px;
    } 

    .single-category {
        padding:20px 10px;
    }

    .menu-info {
        padding: 20px 10px;
    }
}

</style>