<template>
<div>
  <form @submit.prevent="handleSubmit">
    <h3>Login</h3>    
    <input type="email" required placeholder="email" v-model="email">
    <input type="password" required placeholder="password" v-model="password">
    <div v-if="error" class="error">{{ error }}</div>    
    <button v-if="!isPending">Log in</button>
    <button v-if="isPending" disabled>Loading</button>
  </form>
  <button id="googleBtn" @click="googleLogin">GOOGLE Login</button>
</div>  
</template>

<script>
import { ref } from 'vue'
import useLogin from '@/composables/useLogin'
import { useRouter } from 'vue-router'
import { googleAuth, projectAuth } from '@/firebase/config'

export default {
  setup() {
    // refs    
    const email = ref('')
    const password = ref('')  

    const { error, login, isPending } = useLogin()
    const router = useRouter()

    const googleLogin = () => {      
      var provider = googleAuth.addScope('https://www.googleapis.com/auth/contacts.readonly');
      projectAuth.signInWithRedirect(provider);    
    }

    const handleSubmit = async () => {
      await login(email.value, password.value)

      //console log errors 
      if (!error.value) {          
          router.push({ name: "Dashboard" })
      } else {
          console.log(error.value)
      }   
      
    }
    
    return { googleLogin, email, password, handleSubmit, error, isPending }
  }
}
</script>
