<template>
<div>    
    <div class="add-item item-create">  
        <button class="close-btn" @click="closeCreateMenu">Close</button>      
        <form @submit.prevent="handleSubmit">
            <h4>Create Your Menu</h4>
            <p>Please fill in all the required fields to get started.<br> You may always fill in other inforamation at a later date.</p>
            <div class="error" v-if="error">{{ error }}</div>
            
                <label>Menu Information</label>
                <input type="text" required placeholder="Menu Title" v-model="menuTitle">                
                <input type="text" placeholder="Menu Description" v-model="menuDesc">
                <!-- <input type="text" placeholder="Location" v-model="menuLocation"> -->
                <vue-google-autocomplete
                    id="map"
                    classname="form-control"
                    placeholder="Address"
                    v-on:placechanged="getAddressData"
                    v-model="menuLocation"
                >
                </vue-google-autocomplete>
                <input type="tel" pattern="[0-9]{10}" maxlength="12" placeholder="Phone Number - 1234567890" v-model="menuPhoneNumber">
                
                <label>Upload Cover Image</label>
                <div class="img-section">
                    <input type="file" @change="handleChange">
                    <img id="uploadImgCover" width="130" height="130">                                
                </div>                
                <div class="error">{{ fileError }}</div>                                                           
            <button v-if="!isPending">Create</button>
            <button v-else disabled>Saving...</button>
        </form>        
    </div>
</div>  
</template>

<script>
//Components

//Composables
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

//Vue
import { ref } from 'vue'
import { useRouter } from 'vue-router'
//Firebase
import { timestamp, projectFirestore } from '@/firebase/config'

export default {  
  emits: ['closeMenu'], 
  components: { VueGoogleAutocomplete }, 
  setup(props, { emit }) {
    const uniqid = require('uniqid');      

    const { filePath, url, uploadImage } = useStorage()
    const { addDoc } = useCollection('userMenu')
    const { user } = getUser()
    const router = useRouter()  
    
    //create menu
    const menuTitle = ref('')
    const menuDesc = ref('')
    const menuPhoneNumber = ref('')

    //get location
    const menuLocation = ref('')     
    const menuLocationUrl = ref('')

    const getAddressData = (addressData, placeResultData, id) => {                                    
        menuLocation.value = placeResultData.formatted_address
        menuLocationUrl.value = placeResultData.url                       
    }
    
    //upload images
    const file = ref(null)
    const fileError = ref(null)

    //check progress
    const isPending = ref(false)    
    const error = ref(null)

    const imgUrl = ref('')
    const imgPath = ref('')

    const handleSubmit = async () => {

        const menuRef = projectFirestore.collection('userMenu').orderBy('createdAt')
        const snapshot = await menuRef.where('menuTitle', '==', menuTitle.value.toLowerCase()).get()
        if (snapshot.empty) {
            console.log('No matching documents.')
            error.value = null

            if (file.value) {                
                //might need to check if image exists then delete
                // await deleteImage(props.menuImgPath)            
                await uploadImage('covers', file.value)
                imgUrl.value = url.value
                imgPath.value = filePath.value                
            } 

            
            isPending.value = true
            
            const newMenu = {
                menuTitle: menuTitle.value.toLowerCase(),              
                menuUrl: menuTitle.value.toLowerCase().replace(/[^A-Z0-9]+/ig, "-"),
                menuDescription: menuDesc.value,               
                menuLocation: menuLocation.value,
                menuLocationUrl: menuLocationUrl.value,
                menuPhoneNumber: menuPhoneNumber.value,
                userId: user.value.uid,                
                coverUrl: imgUrl.value,
                filePath: imgPath.value, 
                createdAt: timestamp()
            }

            await addDoc(newMenu)
            if (!error.value) {                
                isPending.value = false                                             
                // router.push({ name: 'UserMenu', params: { menuTitle:  menuTitle.value.toLowerCase() } })
                router.push({ name: 'MenuBuilder' })
            } 

        } else {
            snapshot.forEach(doc => {
            // console.log(doc.id, '=>', doc.data())
            console.log('user name already exists')          
            error.value = "Menu name already exists please create a new one."        
            })
        }

    }
          
    //allow file type for images
    const types = ['image/png', 'image/jpeg', 'image/png']

    const handleChange = (e) => {
        const selected = e.target.files[0]  
        
        const image = document.getElementById('uploadImgCover');            
        image.src = URL.createObjectURL(selected);

        if (selected && types.includes(selected.type) ) {
            file.value = selected
            fileError.value = null
        } else {
            file.value = null
            fileError.value = 'you can only upload png or jpg file types'
        }

    }

    const closeCreateMenu = () => {
      emit('closeMenu')
    }

    // return { menuTitle, menuDesc, firstName, lastName, location, phoneNumber, handleSubmit, handleChange, fileError, isPending, error }
    return { getAddressData, closeCreateMenu, menuLocation, menuPhoneNumber, menuTitle, menuDesc, handleSubmit, handleChange, fileError, isPending, error }
  }
}
</script>

<style scoped>
img {
    width:100%;
    border-radius:10px;
    object-fit: cover;
    background:#c0c0c0;
}

.img-section input {
    border:none;
}

.item-create {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    position: absolute;
    max-width: 500px;    
    width:100%;
    max-height:100%;
    overflow:scroll;
}

form button {
    width:100%;
}
</style>