<template>
<div>
    <div class="add-item">        
        <form @submit.prevent="handleSubmit">
            <h4>Create Your Menu</h4>
            <p>Please fill in all the required fields to get started.<br> You may always fill in other inforamation at a later date.</p>
            <div class="error" v-if="error">{{ error }}</div>
            
                <label>Menu Information</label>
                <input type="text" required placeholder="Menu Title" v-model="menuTitle">                
                <input type="text" placeholder="Menu Description" v-model="menuDesc">
                <!-- <input type="text" placeholder="Location" v-model="menuLocation"> -->
                <vue-google-autocomplete
                    id="map"
                    classname="form-control"
                    placeholder="Location"
                    v-on:placechanged="getAddressData"
                    v-model="menuLocation"
                >
                </vue-google-autocomplete>
                <input type="text" placeholder="Phone Number" v-model="menuPhoneNumber">
                
                <label>Upload Cover Image</label>
                <div class="img-section">
                    <input type="file" @change="handleChange">
                    <img id="uploadImgCover" :src="menuImgUrl" width="130" height="130">                                
                </div>                
                <div class="error">{{ fileError }}</div>                               
                            

            <button v-if="!isPending">Create</button>
            <button v-else disabled>Saving...</button>
        </form>
    </div>
</div>  
</template>

<script>
//Components

//Composables
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

//Vue
import { ref } from 'vue'
import { useRouter } from 'vue-router'
//Firebase
import { timestamp, projectFirestore } from '@/firebase/config'

export default {
//   props: ['meunCat'],  
  components: { VueGoogleAutocomplete }, 
  setup() {
    const uniqid = require('uniqid');      

    const { filePath, url, uploadImage } = useStorage()
    const { addDoc } = useCollection('userMenu')
    const { user } = getUser()
    const router = useRouter()  
    
    //create menu
    const menuTitle = ref('')
    const menuDesc = ref('')

    //add personal data
    // const firstName = ref('')
    // const lastName = ref('')
    const menuLocation = ref('')
    const menuPhoneNumber = ref('')
    
    //upload images
    const file = ref(null)
    const fileError = ref(null)

    //check progress
    const isPending = ref(false)    
    const error = ref(null)

    const imgUrl = ref('')
    const imgPath = ref('')

    const handleSubmit = async () => {

      const menuRef = projectFirestore.collection('userMenu').orderBy('createdAt')
      const snapshot = await menuRef.where('menuTitle', '==', menuTitle.value.toLowerCase()).get()
      if (snapshot.empty) {
        console.log('No matching documents.')
        error.value = null

          if (file.value) {
            isPending.value = true

            await uploadImage('covers', file.value)
            const newMenu = {
                menuTitle: menuTitle.value.toLowerCase(),              
                menuUrl: menuTitle.value.toLowerCase().replace(/[^A-Z0-9]+/ig, "-"),
                menuDescription: menuDesc.value,
                // accountFirstName: firstName.value,
                // accountLastName: lastName.value,
                menuLocation: menuLocation.value,
                menuPhoneNumber: menuPhoneNumber.value,
                userId: user.value.uid,                
                coverUrl: url.value,
                filePath: filePath.value, 
                createdAt: timestamp()
            }

            await addDoc(newMenu)
            if (!error.value) {                
                isPending.value = false 
                
                            
                // router.push({ name: 'UserMenu', params: { menuTitle:  menuTitle.value.toLowerCase() } })
                router.push({ name: 'Dashboard' })
            } 

        } 

      } else {
        snapshot.forEach(doc => {
          // console.log(doc.id, '=>', doc.data())
          console.log('user name already exists')          
          error.value = "Menu name already exists please create a new one."        
        })
      }


    
    }
          
    //allow file type for images
    const types = ['image/png', 'image/jpeg', 'image/png']

    const handleChange = (e) => {
        const selected = e.target.files[0]  
        
        const image = document.getElementById('uploadImgCover');            
        image.src = URL.createObjectURL(selected);

        if (selected && types.includes(selected.type) ) {
            file.value = selected
            fileError.value = null
        } else {
            file.value = null
            fileError.value = 'you can only upload png or jpg file types'
        }

    }

    // return { menuTitle, menuDesc, firstName, lastName, location, phoneNumber, handleSubmit, handleChange, fileError, isPending, error }
    return { menuLocation, menuPhoneNumber, menuTitle, menuDesc, handleSubmit, handleChange, fileError, isPending, error }
  }
}
</script>

<style scoped>
/* .add-item {
  text-align: center;
  margin-top: 40px;
} */
form {
  max-width: 500px;
  text-align: left;
}  

img {
  width:100%;
  border-radius:10px;
  object-fit: cover;
  background:#c0c0c0;
}

.img-section input {
    border:none;
}

    .item-create {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        position: absolute;
        max-width: 500px;    
        width:100%;
        max-height:100%;
        overflow:scroll;
    }

form button {
    width:100%;
}
</style>