<template>  
    <div class="info-section popup">      
        <div v-for="doc in document" :key="doc.id">            
            <p v-if="doc.menuDescription">{{doc.menuDescription}}</p>
            <a :href="doc.menuLocationUrl" target="_blank"><p v-if="doc.menuLocation">{{doc.menuLocation}}</p></a>
            <p v-if="doc.menuPhoneNumber">{{doc.menuPhoneNumber}}</p>                        
        </div>  
    </div>                           
</template>

<script>

export default {
    props:['document', 'edit'],
    setup(props) {  

        return { }
    },

}

</script>

<style scoped>

.info-section p {
    margin-bottom:10px;
}

.menu-info {
    display: grid;
    grid-template-columns: 1fr 170px;
    grid-gap: 20px;    
    padding: 20px 20px;
    padding-bottom:0px;
}

@media(max-width:600px) {
    .menu-info {
        padding:0px 10px;  
    }
}
</style>