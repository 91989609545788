<template>
  <div class="home">
    <h1>SOMETHING EXCITING IS COMING SOON</h1>
    <AllMenus />
  </div>
</template>

<script>
import {projectAuth} from '@/firebase/config'
import { useRouter } from 'vue-router'
import AllMenus from '@/components/AllMenus'

export default { 
  components: {AllMenus}, 
  setup() {
    const router = useRouter()

    
    projectAuth.getRedirectResult().then((result) => {
      if (result.credential) {        
        // var credential = result.credential;
        // var token = credential.accessToken;
        router.push({ name: "Dashboard" })
      }            
      // var user = result.user;      
    }).catch((error) => {      
      var errorCode = error.code;
      var errorMessage = error.message;
      var email = error.email;
      var credential = error.credential;   
      router.push({ name: "Login" })   
      //we may need to pass error message      
    });


  }
}
</script>

<style>
.home {
  max-width: 1700px;
  padding: 20px;
}
</style>