<template>    
    <div>          
        <div>
            <div class="cover-img" v-for="doc in document" :key="doc.id">
                <img v-if="doc.coverUrl" :src="doc.coverUrl"/>            
                <img v-else />            
                <router-link :to="{ path: '/menu/' + doc.menuUrl }"><h1>{{doc.menuTitle}}</h1></router-link>
            </div>
            <div class="menu-info">
                <MenuInfo :document="document" />                                 
                <button class="edit edit-cover" v-if="edit" @click="editMenu">Edit Menu Info</button>               
            </div>
                        
        </div>
        
        <div v-if="showEditMenu" class="floater updateMenu" >                       
            <UpdateMenu @closeMenu="closeEditMenu" :docId="docId" :document="document" :menuTitle="menuTitle" :menuDesc="menuDesc" :menuLocationUrl="menuLocationUrl" :menuLocation="menuLocation" :menuPhoneNumber="menuPhoneNumber" :menuImgPath="menuImgPath" :menuImgUrl="menuImgUrl" :menuId="menuId" />         
        </div>          
    </div>

     
</template>

<script>
import MenuInfo from '@/components/MenuInfo.vue'
import UpdateMenu from '@/components/UpdateMenu.vue'
import { ref } from 'vue'

export default {    
    props:['document', 'edit', 'docId'],
    components:{MenuInfo, UpdateMenu},
    setup(props) {  
        
    const menuTitle = ref(props.document[0].menuTitle)
    const menuDesc = ref(props.document[0].menuDescription)
    const menuLocation = ref(props.document[0].menuLocation)
    const menuLocationUrl = ref(props.document[0].menuLocationUrl)
    const menuPhoneNumber = ref(props.document[0].menuPhoneNumber)
    const menuImgPath = ref(props.document[0].filePath)
    const menuImgUrl = ref(props.document[0].coverUrl) 
    const menuId = ref(props.document[0].id)

    const showEditMenu = ref(false)       
    const editMenu = async (e) => { 
        showEditMenu.value = !showEditMenu.value
           
        const getMenuValues = await props.document                            
        menuTitle.value = getMenuValues[0].menuTitle
        menuDesc.value = getMenuValues[0].menuDescription
        menuLocation.value = getMenuValues[0].menuLocation
        menuPhoneNumber.value = getMenuValues[0].menuPhoneNumber
        menuImgPath.value = getMenuValues[0].filePath
        menuImgUrl.value = getMenuValues[0].coverUrl
        menuId.value = getMenuValues[0].id
        
        document.getElementById('map').value = menuLocation.value            
    }
    const closeEditMenu = () => {
       showEditMenu.value = !showEditMenu.value 
    }

        return { closeEditMenu, showEditMenu, editMenu, menuTitle, menuDesc, menuLocation, menuLocationUrl, menuPhoneNumber, menuImgPath, menuImgUrl, menuId}
    
    }

}

</script>

<style scoped>

.cover-img {
    width:100%;
    height:211px;
    display: block;
    position:relative;
    background:#eee;
}

.cover-img:after {
    content:'';
    width:100%;
    height:211px;
    position:absolute;
    top:0;
    left:0;
    background:black;
    opacity:0.33;
    z-index: 1;        
}    

.cover-img img {
    width:100%;
    height:211px;
    object-fit: cover;
}

h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: capitalize;
    color:white;
    z-index: 2;
}

.menu-info {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;    
    padding: 20px 20px;
    padding-bottom:0px;
}

@media(max-width:600px) {
    .menu-info {
        padding:20px 10px;  
    }
}

.edit-cover {
    position:absolute;
    top:0;
    left:50%;
    transform:translate(-50%, -50%);
    z-index: 5;
}

</style>