<!-- 
This is our category list.
1. amount of items
2. edit category page? 
-->

<template>
<div>    
    <div class="top-cat-list">    
        <div class="top-cat-list-holder">     
        <draggable v-model="menuCats" @end="saveCatMove" handle=".moveBtn" class="cat-list">                    
            <span v-for="cat in menuCats" :key="cat.id">
                <p @click="scrollFix(cat.titleAnchor)">{{cat.title}}</p>                        
                <button v-if="edit" class="moveBtn sm-btn">Move</button>            
            </span>
        </draggable>
        </div>
        <button class="create" v-if="edit" @click="toggleCreateCategory">Create New Category</button>
    </div>
    


    <div class="menus" >        
        <div class="single-category popup" v-for="cat in menuCats" :key="cat.id">                       
            
            <div class="category" :data-cat-id="cat.id" :data-pos="cat.position">
                <div class="cat-info-bar">
                    <span>
                        <h3 :id="cat.titleAnchor">{{ cat.title }}</h3>  
                        <button class='edit' v-if="edit" @click="editCategory">Edit Category Title</button>
                    </span>

                    <span>
                        <p class="item-amount" >{{ cat.menuItems.length }} Items</p>                                             
                        <button class="create" v-if="edit" @click="addItem">Add New Item</button>
                    </span>    
                    
                    
                    <button class="remove" v-if="edit" @click="deleteCategory">Delete Category</button>                        
                    
                    
                </div>
                
                <draggable class="menu-items-list" v-model="cat.menuItems" @end="saveItemMove" handle=".moveBtn">                    
                    <div v-for="item in cat.menuItems" :key="item.id" class="item" :data-item-id="item.id">
                        <div class="item-details">
                            <section class="text">
                                <h3>{{item.title}}</h3>                              
                                <p v-if="item.description" class="desc">{{item.description}}</p>
                                <p v-if="item.price" class="price">${{item.price}}</p>
                            </section>
                            <section v-if="item.coverUrl" class="image">
                                <img :src="item.coverUrl">
                            </section>
                        </div>  
                        <div v-if="edit" class="item-options">                                                                     
                            <button class="remove sm-btn" @click="deleteItem">Delete</button>                       
                            <button class="edit sm-btn" @click="editItem">Edit</button>                                
                            <button class="moveBtn sm-btn" @click="enableMove">Move</button>                                
                        </div>
                    </div>   
                </draggable>

            </div> 

        </div>       
    </div> 

    <div v-if="showAddItems" class="floater addItem" >                       
        <AddItem :menuCat="menuCats" :menuCatId="menuCatId" @closeItem="closeAddItem" />         
    </div>  

    <div v-if="showUpdateItems" class="floater updateItem" >                       
        <UpdateItem :menuCat="menuCats" :menuCatId="menuCatId" :itemTitle="itemTitle" :itemDesc="itemDesc" :itemPrice="itemPrice" :itemImgUrl="itemImgUrl" :itemPath="itemPath" :itemId="itemId" @closeItemUpdate="closeUpdateItem" />         
    </div>

    <div v-if="showCreateCategory" class="floater createCategory" >                       
        <CreateCategory @closeCategory="toggleCreateCategory"/>         
    </div>    

    <div v-if="showEditCateogry" class="floater updateCategory" >                       
        <UpdateCategory :menuCatId="menuCatId" :menuCatValue="menuCatValue" @closeCategoryUpdate="closeCategoryUpdate" />         
    </div>    
    
</div>  
</template>

<script>
import AddItem from '@/components/AddItem.vue'
import UpdateItem from '@/components/UpdateItem.vue'
import { ref, defineComponent, onMounted, onUpdated } from 'vue'
import useDocument from '@/composables/useDocument'
import useStorage from '@/composables/useStorage'  
import { VueDraggableNext } from 'vue-draggable-next'
import { timestamp } from '@/firebase/config'
import CreateCategory from '@/components/CreateCategory.vue'
import UpdateCategory from '@/components/UpdateCategory.vue'
import stickyScroll from '@/composables/stickyScroll'


export default {
  props: ['menuCats', 'edit'],
  components: { UpdateCategory, CreateCategory, AddItem, UpdateItem, draggable: VueDraggableNext },
  setup(props) {          
      
    const menuCatId = ref(null)
    const menuCatValue = ref(null)
    const itemTitle = ref(null)
    const itemDesc = ref(null)
    const itemPrice = ref(null)
    const itemImgUrl = ref(null)
    const itemPath = ref(null)
    const itemId = ref(null)   

    const { deleteImage } = useStorage()
    
    const scrollFix = (hashbang) => {            
        location.href = '#' + hashbang;

        const id = hashbang;
        const yOffset = -140; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;    

        window.scrollTo({top: y});  
    }

    
    const chosenCatId = ref(null)    
    const saveCatMove = async () => {        
        const newCatArr = document.querySelectorAll('.category')                
        
        await newCatArr.forEach(async (item, index) => {
            const { updateDoc } = await useDocument('menuCategory', item.getAttribute('data-cat-id') )
            await updateDoc({
                position: index
            })              
        });
        
    }

    const saveItemMove = (e) => {
        const getCatId = e.composedPath()[1].getAttribute('data-cat-id')
        const newListArrangement = props.menuCats.filter((cat) => cat.id == getCatId)
        
        const { updateDoc } = useDocument('menuCategory', getCatId)
        updateDoc({
            menuItems: newListArrangement[0].menuItems
        })
        console.log('new list saved')
    }

    const showAddItems = ref(false)
    const addItem = (e) => {                                              
        menuCatId.value = e.composedPath()[3].getAttribute('data-cat-id')                
        showAddItems.value = !showAddItems.value
    }  
    const closeAddItem = () => {
        showAddItems.value = !showAddItems.value
    }

    const showUpdateItems = ref(false)
    const editItem = async (e) => {
            const getCatId = e.composedPath()[4].getAttribute('data-cat-id')
            const theCat = props.menuCats.filter((cat) => cat.id == getCatId)
            const catItems = theCat[0].menuItems 
            const getItemId = e.composedPath()[2].getAttribute('data-item-id')
            
            const getItemValues = catItems.filter((item) => item.id === getItemId )                
            
            itemTitle.value = getItemValues[0].title
            itemDesc.value = getItemValues[0].description
            itemPrice.value = getItemValues[0].price
            itemImgUrl.value = getItemValues[0].coverUrl
            itemPath.value = getItemValues[0].filePath
            itemId.value = getItemValues[0].id
            menuCatId.value = getCatId
            
            showUpdateItems.value = !showUpdateItems.value
    }
    const closeUpdateItem = () => {
        showUpdateItems.value = !showUpdateItems.value
    }

    const deleteCategory = async (e) => {        
        // if (confirm('Are you want to delete this category and ALL of its items?')) {            
            const getCatId = e.composedPath()[2].getAttribute('data-cat-id')
            const theCat = props.menuCats.filter((cat) => cat.id == getCatId)
            const catItems = theCat[0].menuItems
                    
            await catItems.forEach(async (item) => {
                await deleteImage(item.filePath)                               
            });

            const { deleteDoc } = useDocument('menuCategory', getCatId)
            await deleteDoc()          
        // }           
    }


    const deleteItem = async (e) => {        
        const getCatId = e.composedPath()[4].getAttribute('data-cat-id')
        const getItemId = e.composedPath()[2].getAttribute('data-item-id')
        
        const theCat = props.menuCats.filter((cat) => cat.id == getCatId)
        
        const { updateDoc, deleteDoc } = useDocument('menuCategory', getCatId)

        const menuItems = theCat[0].menuItems.filter((item) => item.id != getItemId )                
        const itemsImage = theCat[0].menuItems.filter((item) => item.id == getItemId ) 
                        
        await deleteImage(itemsImage[0].filePath)
        await updateDoc({ menuItems })
    }


    const showCreateCategory = ref(false)
    const toggleCreateCategory = () => {
        showCreateCategory.value = !showCreateCategory.value        
    }

    const showEditCateogry = ref(false)
    const editCategory = (e) => {        
        const getCatId = e.composedPath()[3].getAttribute('data-cat-id')        
        const getCatValue = e.composedPath()[2].childNodes[0].childNodes[0].innerText      

        menuCatId.value = getCatId        
        menuCatValue.value = getCatValue

        showEditCateogry.value = !showEditCateogry.value
    }
    const closeCategoryUpdate = () => {
        showEditCateogry.value = !showEditCateogry.value        
    }

    onMounted(() => {
        stickyScroll()
        console.log('loaded scroll')
    })

    onUpdated(() => {
        // stickyScroll()
        console.log('loaded updated')
    })

    return { closeUpdateItem, closeCategoryUpdate, showCreateCategory, toggleCreateCategory, editCategory, showAddItems, showUpdateItems, showEditCateogry, scrollFix, chosenCatId, saveCatMove, saveItemMove, addItem, closeAddItem, menuCatValue, menuCatId, deleteItem, deleteCategory, editItem, itemTitle, itemDesc, itemPrice, itemImgUrl, itemPath, itemId }
  }
}
</script>

<style scoped>

.top-cat-list {
    position: sticky;
    position: -webkit-sticky;
    top: 68px;
    z-index: 2;
    background: var(--bg);
    padding: 10px 20px;
    /* border-radius: 20px; */
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap:10px;
    margin:30px 0px;
    align-items: center;
}

@media(max-width:600px) {
    .top-cat-list {
        grid-template-columns: 1fr;
        padding:10px 10px;
        top:52px;
    }
}

.top-cat-list span {
    margin-right:20px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}

.top-cat-list .active p {
    text-decoration:underline;
}

.top-cat-list-holder {
    overflow-y: hidden;
    white-space: nowrap;
}

.isSticky {
    border-bottom: 1px solid var(--secondary);
}

.menus {
    padding: 0px 20px 20px;
}

.cat-list {
    display:flex;
}

.cat-info-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom:10px;
}

.cat-info-bar span {
    display:flex;
    align-items: center;    
}

.single-category button {
    margin-left:5px;
}


.cat-info-bar h3 {
    text-transform: capitalize;
    font-weight: bold;
}

@media(max-width:675px) {
    .cat-info-bar {
        display:block;
    }

    .cat-info-bar span  {
        justify-content: space-between;
        margin-bottom:5px;
    }
}

.single-category {      
    margin-bottom:40px;
}

.item-details {
    display:grid;
    grid-template-columns:1fr auto;
    grid-gap:10px;
    padding:10px;
    border:1px solid var(--secondary);
    /* border-bottom:5px solid var(--corvette); */
    border-radius:10px;
    background:white;
}

.item-details section {
    height:110px;
    position:relative;
}

.item-details .text h3 {
    text-transform: capitalize;
}

.item-details .text .desc{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.item-details .text .price{
    position:absolute;
    bottom:0;
    font-weight:bold;    
}

.item-details img {
    width: 110px;
    height:110px;
    object-fit: cover; 
    border-radius:10px;   
}

.delete {
    margin:0px;
    text-align: center;
    padding: 0;
}

.menu-items-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:20px;
}

@media(max-width:1300px) {
    .menu-items-list {     
        grid-template-columns: repeat(2, 1fr);        
    }  
}

@media(max-width:765px) {
    .menu-items-list {     
        grid-template-columns: repeat(1, 1fr);        
    }  
}



.category-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.category-info button {
    margin-top:0px;
}

@media(max-width:600px) {
   .menus {
        padding: 0px 10px 20px;
    } 

    .single-category {
        padding:20px 10px;
    }
}

.item-options button:first-child {
    margin-left:0px;
}

</style>