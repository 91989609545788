<template>     
    <div id="dashboard">
        <section class="popup">
            <h3>Profile</h3>
            <Profile />
        </section>
        <section class="popup">
            <h3>Menu</h3>
            <MenuList />  
        </section>      
        <section class="popup">
            <h3>QR Code</h3>
        </section>
    </div>
</template>

<script>
import MenuList from '@/components/MenuList.vue'
import Profile from '@/components/Profile.vue'
export default {    
    components: {MenuList, Profile},
    setup() {         

        
        return {}
    }
}
</script>

<style scoped>
    #dashboard {
        display:grid;
        grid-template-columns: 1fr;
        grid-gap:20px;
        padding:20px;        
    }
</style>