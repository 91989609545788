//Vue
import { createRouter, createWebHistory } from 'vue-router'

//Authentication Views
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import Signup from '../views/auth/Signup.vue'

//Menu Views
import UserMenu from '../views/menus/UserMenu.vue'
import UserMenuCreateCat from '../views/menus/UserMenuCreateCat.vue'
import UserMenuCatDetails from '../views/menus/UserMenuCatDetails.vue'
import CreateMenu from '../views/menus/CreateMenu.vue'
// import Test from '../views/playlists/Test.vue'

//Profile Views
import Dashboard from '../views/profile/Dashboard.vue'
import MenuBuilder from '../views/profile/MenuBuilder.vue'

// route guard
import { projectAuth } from '../firebase/config';


const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (!user) {
    next({ name: 'Login' })
  } else {
    next()
  }
}

const requiredNoAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if(user) {
    next({ name: 'Home' })
  } else {
    next()
  } 
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,    
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: requiredNoAuth
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: requiredNoAuth
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: requireAuth
  },
  {
    path: '/dashboard/menu-builder/',
    name: 'MenuBuilder',
    component: MenuBuilder,
    beforeEnter: requireAuth    
  },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: Test,
  //   beforeEnter: requireAuth
  // },
  // {
  //   path: '/playlists/:id',
  //   name: 'PlaylistDetails',
  //   component: PlaylistDetails,
  //   beforeEnter: requireAuth,
  //   props: true
  // },
  // {
  //   path: '/playlists/user',
  //   name: 'UserPlaylists',
  //   component: UserPlaylists,
  //   beforeEnter: requireAuth    
  // },
  {
    path: '/:menuUrl',
    name: 'UserMenu',
    component: UserMenu,    
    props: true    
  },
  {
    path: '/create-menu',
    name: 'CreateMenu',
    component: CreateMenu,
    beforeEnter: requireAuth    
  },
  {
    path: '/dashboard/create-new-category',
    name: 'UserMenuCreateCat',
    component: UserMenuCreateCat,
    beforeEnter: requireAuth    
  },
  {
    path: '/dashboard/category/:id',
    name: 'UserMenuCatDetails',
    component: UserMenuCatDetails,
    beforeEnter: requireAuth ,
    props: true   
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router