<template>
<div>
    <div class="add-item item-create">                 
        <button class="close-btn" @click="closeAddItem">Close</button>
        <form @submit.prevent="handleSubmit" id="addItemForm">                          
            <h4>Add New Menu Item</h4>
            <input type="text" required placeholder="Item title" v-model="title">
            <input type="text" placeholder="Item description..." v-model="description"/>
            <input type="text" placeholder="Item price" v-model="price">

            <!-- lets add varients -->

            <!-- image upload --> 
            <label>Upload Item  Image</label>
            <div class="img-section">
                <input type="file" @change="handleChange">
                <img id="imgPreview" width="130" height="130">    
            </div>           
            <div class="error">{{ fileError }}</div>
            

            <button v-if="!isPending">Create</button>
            <button v-else disabled>Saving...</button>
        </form>
    </div>
</div>  
</template>

<script>
import { ref } from 'vue'
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
import getUser from '@/composables/getUser'
import { timestamp } from '@/firebase/config'
import { useRouter } from 'vue-router'
import useDocument from '@/composables/useDocument'

export default {
  emits: ['closeItem'],
  props: ['menuCat', 'menuCatId'],  
  setup(props, {emit}) {         
    const uniqid = require('uniqid');    

    const passMenuItem = ref('null')

    const { filePath, url, uploadImage } = useStorage()
    const { error, addDoc } = useCollection('menuCategory')
    const { user } = getUser()
    const router = useRouter()    

    const title = ref('')
    const description = ref('')
    const price = ref('')
    const file = ref(null)
    const fileError = ref(null)
    const isPending = ref(false)

    const fileValueUrl = ref(null)
    const fileValuePath = ref(null)

    const closeAddItem = () => {
        emit('closeItem')
    }    

    const handleSubmit = async () => {        
        const imageVal = document.querySelector('input[type="file"]');
        const imageSrc = document.getElementById('imgPreview');              
        
        const { updateDoc } = await useDocument('menuCategory', props.menuCatId)

        if (props.menuCatId !== null) {
            const getCategory = props.menuCat.filter(cat => cat.id == props.menuCatId);        
            passMenuItem.value = getCategory[0].menuItems                        
        }

        isPending.value = true

        if (file.value) {            
            await uploadImage('menuItems', file.value)
            fileValueUrl.value = url.value  
            fileValuePath.value = filePath.value                                  
        } else {
            fileValueUrl.value = null
            fileValuePath.value = null                      
        }             

        const newItem = {
            title: title.value,              
            description: description.value,
            price: price.value,
            userId: user.value.uid,
            // userName: user.value.displayName,  
            coverUrl: fileValueUrl.value,
            filePath: fileValuePath.value,
            id: uniqid()
        }            

        await updateDoc({
            menuItems: [...passMenuItem.value, newItem]
        })

        isPending.value = false
        if (!error.value) {
            console.log('new item created');                
            //clear all the old input
            title.value = ''
            description.value = ''
            price.value = ''
            file.value = null
            imageVal.value = ''            
            imageSrc.removeAttribute('src')
            emit('closeItem')
        }
    }

    //allow file type for images
    const types = ['image/png', 'image/jpeg', 'image/png']

    const handleChange = (e) => {        
        const selected = e.target.files[0] 
        
        if (selected && types.includes(selected.type) ) {
            
            const image = document.getElementById('imgPreview');            
            image.src = URL.createObjectURL(selected);

            file.value = selected
            fileError.value = null
            // console.log(makeOwn+file.value.name)
        } else {
            file.value = null
            fileError.value = 'you can only upload png or jpg file types'
        }

    }

    return { title, description, price, handleSubmit, handleChange, fileError, isPending, closeAddItem }
  }
}
</script>

<style scoped>
    img {
        border-radius:10px;
        object-fit: cover;
        background:#c0c0c0;
    }

    .img-section {
        display:grid;
        grid-template-columns:1fr 130px;
    }

    .img-section input {
        border:none;
    }

    .item-create {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        position: absolute;
        max-width: 500px;    
        width:100%;
        max-height:100%;
        overflow:scroll;
    }
    
</style>